import React from "react";
import {Switch} from "@headlessui/react";

const CheckboxSlider = ({title, isChecked, onChange, disabled = false}) => {
    return (
        <Switch.Group as="div" className="py-2 w-full">
            <Switch.Label as="dt" className="text-sm font-medium text-gray-300" passive>{title}</Switch.Label>
            <dd className="mt-1 ext-sm text-gray-900">
                <Switch disabled={disabled} checked={isChecked} onChange={() => onChange(!isChecked)}
                        className={`${isChecked ? "bg-green-600" : "bg-gray-200"} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors 
                        ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-auto disabled:opacity-50 disabled:hover:cursor-not-allowed`}>
                    <span aria-hidden="true" className={`${isChecked ? "translate-x-5" : "translate-x-0"} inline-block h-5 w-5 rounded-full bg-black shadow transform ring-0 transition ease-in-out duration-200`}/>
                </Switch>
            </dd>
        </Switch.Group>
    );
}
export default CheckboxSlider;