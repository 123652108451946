import React, { useEffect, useState, useCallback } from "react";
import DayJS from "dayjs";
import fileDownloader from "js-file-download";
import Axios from "axios";
import prettyBytes from 'pretty-bytes';
import toast from "react-hot-toast";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  TrashIcon,
  VolumeUpIcon,
  DownloadIcon
} from "@heroicons/react/outline";
import AppConfig from "../../../_appConfig"
import {Organisation_Receiver} from "../../../context/organisation";
import API from "../../../shared/API";
import Confirm from "../../../shared/components/Confirm";
import Modal from "../../../shared/components/Modal";

const str_pad_left = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}
const Voicemails = ({organisation}) => {
  const [callRecordings, setCallRecordings] = useState([]);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [playRecordingUrl, setPlayRecordingUrl] = useState(false);

  const getData = useCallback(() => {
    API.org.telephone.voicemails(organisation.data._id).then(res => {
      setCallRecordings(res);
    });
  }, [organisation]);

  useEffect(() => {
    getData();
  }, [getData]);

  const downloadRecording = (id, filename) => {
    API.org.telephone.voicemailLink(organisation.data._id, id).then(res => {
      Axios.get(AppConfig.api_server + "/.." + res.link, {
        responseType: "blob",
      }).then(a => {
        fileDownloader(a.data, filename);
      });
    });
  }
  const playRecording = (id) => {
    API.org.telephone.voicemailLink(organisation.data._id, id).then(res => {
      setPlayRecordingUrl(AppConfig.api_server + "/.." + res.link);
    }).catch(err => {
      toast.error("Unable to retrieve the voicemail at this time.")
    })
  }
  const handleDeleteRecording = () => {
    API.org.telephone.voicemailDelete(organisation.data._id, deletePrompt._id).then(() => {
      setDeletePrompt(false);
      getData();
      toast.success("Successfully deleted voicemail");
    }).catch(() => {
      toast.error("Error deleting voicemail, please try again shortly");
    })
  }

  return (
    <div className="p-2">
      <h1 className="text-3xl p-2 text-center">Voicemail</h1>

      {playRecordingUrl && <Modal onClose={() => setPlayRecordingUrl(false)} title={"Playing Voicemail"}>
        <AudioPlayer
          src={playRecordingUrl}
          showJumpControls={false}
          onError={() => {
            toast.error("Unable to play voicemail due to an error");
            setPlayRecordingUrl(false);
          }}
          onPlayError={() => {
            toast.error("Unable to play voicemail due to an error");
            setPlayRecordingUrl(false);
          }}
        />

        <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
          <button className="px-4 py-2 bg-blue-400 rounded-lg" onClick={() => setPlayRecordingUrl(false)}>Close</button>
        </div>
      </Modal>}
      {deletePrompt && <Confirm
        open={true}
        isMiddle={true}
        title={"Delete recording"}
        onCancel={() => setDeletePrompt(false)}
        onConfirm={handleDeleteRecording}
        message={`Are you sure you would like to permanently delete the voicemail: ${DayJS(deletePrompt.createdAt).format("DD/MM/YYYY hh:mma")} from ${deletePrompt.metadata.from}?`}
      />}

      {callRecordings.length === 0 ? <div className={"p-2 text-center border rounded bg-gray-900 border-blue-800"}>There are no stored voicemails to display.</div> : <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700 ">
        <thead>
          <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase w-52">Date</th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase w-52">From</th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase">Voicemail Inbox</th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase w-32">Duration</th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase w-28">Size</th>
            <th className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase w-40"></th>
          </tr>
        </thead>
        <tbody className="bg-black divide-y divide-gray-700">{callRecordings.map(recording => {
          const minutes = Math.floor(recording.metadata.recordingDuration / 60);
          const seconds = recording.metadata.recordingDuration - minutes * 60;

          return (<tr key={recording._id} className="items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
            <td className="px-2 py-2 text-md text-gray-200">{DayJS(recording.createdAt).format("DD/MM/YYYY hh:mma")}</td>
            <td className="px-2 py-2 text-md text-gray-200">{recording.metadata.from}</td>
            <td className="px-2 py-2 text-md text-gray-200">{recording.metadata.voicemail_name}</td>
            <td className="px-2 py-2 text-md text-gray-200">{str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2)}</td>
            <td className="px-2 py-2 text-md text-gray-200">{prettyBytes(recording.length)}</td>
            <td className="px-2 py-2 text-md text-gray-200 flex gap-2 justify-end">
              <button className={"rounded-3xl border p-1"} onClick={() => playRecording(recording._id)}><VolumeUpIcon className={"h-6"} /></button>
              <button className={"rounded-3xl border p-1"} onClick={() => downloadRecording(recording._id, recording.filename)}><DownloadIcon className={"h-6"} /></button>
              <button className={"rounded-3xl border p-1 text-red-500"} onClick={() => setDeletePrompt(recording)}><TrashIcon className={"h-6"} /></button>
            </td>
          </tr>);
        })}</tbody>
      </table>}
    </div>
  );
};
export default Organisation_Receiver(Voicemails, (organisation) => {
  return {organisation};
});