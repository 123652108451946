import Toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import CheckboxSlider from "../../../../../shared/components/CheckboxSlider";
import API from "../../../../../shared/API"

const EntraSettings = ({organisation, canChangeSignin, parentRefresh}) => {
  const [config, setConfig] = useState({
    isEnabled: false
  });
  const [consentUrl, setConsentUrl] = useState("loading..")
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getData = () => {
    API.org.settings.entra_id.getConsentUrl(organisation.data._id).then(res => {
      setConsentUrl(res);
    });
    API.org.settings.entra_id.getConfig(organisation.data._id).then((res) => {
      setConfig(res);
      canChangeSignin(res.isEnabled)
    });
  }

  useEffect(() => {
    getData();
  }, [organisation]);

  const handleConfigSubmit = () => {
    setIsSubmitting(true);
    API.org.settings.entra_id.setConfig(organisation.data._id, config).then(() => {
      getData();
      parentRefresh();
      Toast.success("Successfully update Microsoft Entra ID Settings.")
    }).catch(error => {
      let errorMessage = "";
      if(error.response !== undefined){
        errorMessage = error.response.data.message;
      }
      Toast.error(errorMessage);
    }).finally(() => {
      setIsSubmitting(false);
    });
  }

  return (
    <>
      <div className={"border-b-2 border-gray-500 pb-2 mb-2"}>Settings related to Microsoft Entra ID, formally Azure Active Directory.</div>

      <CheckboxSlider title={"Enable"} onChange={() => setConfig({
        ...config,
        isEnabled: !config.isEnabled
      })} isChecked={config.isEnabled}/>

      <div className="flex flex-col gap-1 w-full">
        <label>Tenant ID</label>
        <input
          className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
          type={"text"}
          name={"tenantId"}
          autoComplete={"none"}
          required
          value={config.tenantId || ""}
          onChange={(e) => setConfig({...config, tenantId: e.target.value})}
        />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <label>Consent URL</label>
        <div className={"flex"}>
          <input
            className="w-full p-2 bg-gray-800 rounded-l-md border border-gray-700"
            type={"text"}
            name={"consentUrl"}
            disabled
            value={consentUrl}
          />
          <button className={"border border-gray-300 bg-gray-800 rounded-r-md px-4"} onClick={() => {
            navigator.clipboard.writeText(consentUrl);
            Toast.success("Copied consent url to clipboard")
          }}>Copy
          </button>
        </div>

        <div className={"tracking-wide px-2"}>Before users can sign-in an administrator of the Microsoft Entra must consent to the required permissions</div>
      </div>


      <button disabled={isSubmitting} className="bg-green-600 text-white rounded-xl p-2 mt-4 float-right" onClick={() => handleConfigSubmit()}>Save Changes</button>
      <div className={"clear-both"}>{""}</div>
    </>
  );
}
export default EntraSettings;