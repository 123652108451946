import CheckboxSlider from "../../../../shared/components/CheckboxSlider";
import React, {useEffect, useState} from "react";
import DomainManagement from "./_components/domainManagement"
import Box from "../../../../shared/components/Box";
import EntraSettings from "./_components/entraSettings";
import CustomRules from "./_components/customRules";
import API from "../../../../shared/API";
import Toast from "react-hot-toast";

const MicrosoftEntraId = ({organisation}) => {
  const [signInMode, setSignInMode] = useState("local");
  const [canChangeSignin, setCanChangeSignin] = useState(false);

  const getData = () => {
    API.org.settings.entra_id.getDefault(organisation.data._id).then(data => {
      setSignInMode(data.signInMode);
    }).catch(() => {
      // TODO: Handle error
    });
  }

  useEffect(() => {
    getData();
  }, [organisation]);

  const handleModeChange = (newMode) => {
    API.org.settings.entra_id.setDefault(organisation.data._id, {signInMode: newMode}).then(() => {
      Toast.success("Successfully updated setting.");
      getData();
    }).catch(() => {
      // TODO: Handle error
    });
  }

  return (
    <div className={"p-2 grid gap-4"}>
      <div className={"flex gap-3"}>
        <div className={"w-full flex flex-col gap-3"}>
          <Box title={"Sign-in method"} styles={"p-2"}>
            <div className={"border-b-2 border-gray-500 pb-2 mb-2"}>Select how team members sign-in to SarStuff applications by default.</div>
            <CheckboxSlider title={"SarStuff Central only"} onChange={() => handleModeChange("local")} isChecked={signInMode === "local"}/>
            <CheckboxSlider title={"SarStuff Central or Microsoft Entra"} onChange={() => handleModeChange("both")} disabled={!canChangeSignin} isChecked={signInMode === "both"}/>
            <CheckboxSlider title={"Microsoft Entra only"} onChange={() => handleModeChange("federated")} disabled={!canChangeSignin} isChecked={signInMode === "federated"}/>
          </Box>
          <Box title={"Custom sign-in rules"} styles={"p-2"}>
            <CustomRules organisation={organisation} />
          </Box>
        </div>
        <div className={"w-full flex flex-col gap-3"}>
          <Box title={"Microsoft Entra ID Settings"} styles={"p-2"}>
            <EntraSettings organisation={organisation} canChangeSignin={a => setCanChangeSignin(a)} parentRefresh={() => getData()} />
          </Box>
          <Box title={"Domains"} styles={"p-2"}>
            <DomainManagement organisation={organisation} />
          </Box>
        </div>
      </div>
    </div>
  );
}
export default MicrosoftEntraId;