import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Organisation_Receiver } from "../../../context/organisation";
import CheckboxSlider from "../../../shared/components/CheckboxSlider";
import { FlowDownArrow, FlowStep, FlowStepStageForm } from "./_components";
import API from "../../../shared/API";
import AlertMessage from "../../../shared/components/AlertMessage";

const CallFlowManagement = ({
  selectedPhoneNumber,
  reloadConfig,
  organisation,
}) => {
  const [editingStep, setEditingStep] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (selectedPhoneNumber._id === undefined) return;
    handleGetFlow();
  }, [selectedPhoneNumber]);

  const moveArrayItem = (array, index, delta) => {
    array = [].concat(array);
    const newIndex = index + delta;
    if (newIndex < 0 || newIndex === array.length) return; // Already at the top or bottom.
    const indexes = [index, newIndex].sort(); // Sort the indexes
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); // Replace from the lowest index, two elements, reverting the order
    return array;
  };

  const handleGetFlow = () => {
    API.org.telephone.phone_numbers
      .flow(organisation.data._id, selectedPhoneNumber._id)
      .then((res) => {
        setStages(res);
      });
  };
  const handleFlowSave = (flow) => {
    setStages(flow);
    setIsSubmitting(true);
    API.org.telephone.phone_numbers
      .updateFlow(organisation.data._id, selectedPhoneNumber._id, { flow })
      .then(() => {
        handleGetFlow();
        toast.success(
          "Flow changed successfully deployed to telephone service."
        );
      })
      .catch(() => {
        toast.error("Error deploying flow, please try again shortly");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const handleConfigUpdate = (newConfig) => {
    setIsSubmitting(true);
    API.org.telephone.phone_numbers
      .updateConfig(organisation.data._id, selectedPhoneNumber._id, newConfig)
      .then(() => {
        reloadConfig();
        toast.success("Config successfully deployed to telephone service.");
      })
      .catch(() => {
        toast.error("Error deploying config change, please try again shortly");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="p-2">
      <div className="text-xl p-2 text-center border-b border-zinc-700 mb-4">
        Call Flow steps for{" "}
        <span className="text-primary text-2xl">
          {selectedPhoneNumber.phoneNumber}
        </span>
      </div>

      <div className={`grid sm:grid-cols-2`}>
        <div className={`px-1 order-2 sm:order-1`}>
          <div
            className={
              "text-sm p-2 text-center border rounded bg-gray-900 border-blue-800"
            }
          >
            NOTE: Some team members details are cached by the telephone service
            and will take up-to 20 minutes to be fully applied. Members that are
            currently non-op/off-call shown in gray will be skipped.
          </div>

          <h2 className="py-4 text-3xl text-center">[Start]</h2>

          {stages.map((item, index) => {
            return (
              <FlowStep
                key={index}
                orderNum={index}
                orderTotal={stages.length}
                step={item}
                onReorder={(direction) => {
                  handleFlowSave(moveArrayItem(stages, index, direction));
                }}
                onClick={() => {
                  setEditingStep(index);
                }}
              />
            );
          })}
          {stages.length === 0 ? (
            <div
              className={
                "p-2 border rounded bg-yellow-800 border-yellow-300 text-center"
              }
            >
              There are no steps configured.
            </div>
          ) : (
            <FlowDownArrow />
          )}

          <h2 className="py-4 text-3xl text-center">[End Call]</h2>
        </div>
        <div className={`px-1 order-1 sm:order-2`}>
          <div className={"sticky top-0"}>
            <div className={"border rounded border-zinc-700 pb-2 mb-2"}>
              <h1 className={"text-2xl p-2 text-center"}>Telephone Config</h1>
              <div
                className={
                  "grid grid-cols-2 gap-2 sm:divide-x-0 divide-x-2 divide-zinc-700 "
                }
              >
                <div className="text-center  ">
                  <CheckboxSlider
                    disabled={isSubmitting}
                    title={"Record Telephone Calls"}
                    onChange={(a) => {
                      handleConfigUpdate({
                        callRecording: !selectedPhoneNumber.callRecording,
                      });
                    }}
                    isChecked={selectedPhoneNumber.callRecording}
                  />
                  <div className={"px-2 text-left  text-sm text-gray-400"}>
                    Record the telephone call, please ensure you inform the
                    caller that calls are recorded.
                  </div>
                </div>
                <div className="text-center ">
                  <CheckboxSlider
                    disabled={isSubmitting}
                    title={"Forward Caller ID"}
                    onChange={(a) => {
                      handleConfigUpdate({
                        passCallerId: !selectedPhoneNumber.passCallerId,
                      });
                    }}
                    isChecked={selectedPhoneNumber.passCallerId}
                  />
                  <div className={"px-2 text-left text-sm text-gray-400"}>
                    Should phone calls received by team members appear from the
                    original caller or from {selectedPhoneNumber.phoneNumber}?
                  </div>
                </div>
              </div>
            </div>
            {editingStep !== false ? (
              <FlowStepStageForm
                isEdit
                step={stages[editingStep]}
                onClose={() => setEditingStep(false)}
                onChange={(data) => {
                  handleFlowSave(
                    stages.map((a, i) => {
                      if (i === editingStep) return data;
                      return a;
                    })
                  );
                  setEditingStep(false);
                }}
                onDelete={() => {
                  handleFlowSave(stages.filter((b, c) => c !== editingStep));
                  setEditingStep(false);
                }}
              />
            ) : (
              <div className="border p-2 rounded border-zinc-700">
                <FlowStepStageForm
                  step={{}} // must pass a blank object else component crashes
                  onClose={() => {
                    // Do nothing
                  }}
                  onChange={(data) => {
                    handleFlowSave(stages.concat(data));
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CallFlows = ({ organisation }) => {
  const [assignedPhoneNumbers, setAssignedPhoneNumbers] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState({});

  const getData = useCallback(() => {
    API.org.telephone.phone_numbers.all(organisation.data._id).then((res) => {
      setAssignedPhoneNumbers(res);
      setSelectedPhoneNumber(res[0] !== undefined ? res[0] : {});
    });
  }, [organisation]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (assignedPhoneNumbers.length === 0) {
    return (
      <div className="p-2">
        <AlertMessage.Info className={"text-center"}>
          There is no telephone service assigned to your organisation, please
          contact the SarStuff Sales team to start your trial.
        </AlertMessage.Info>
      </div>
    );
  }

  return (
    <div className="">
      <div className="p-2">
        {assignedPhoneNumbers.length > 1 && (
          <div className={" p-1 rounded px-4  border border-zinc-700 "}>
            <div className="text-xl  text-center border-b border-zinc-800">
              Your Incoming Telephone Numbers
            </div>
            <div className="flex flex-wrap gap-2 p-1">
              {assignedPhoneNumbers.map((a, i) => {
                // console.log(a);

                const style =
                  selectedPhoneNumber._id === a._id
                    ? "text-gray-100 bg-green-900 bg-opacity-50 border-green-500 border"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white bg-gray-800 border-zinc-700 border";
                return (
                  <div
                    key={i}
                    className={` cursor-pointer  text-lg p-2 rounded-lg ${style}`}
                    onClick={() => setSelectedPhoneNumber(a)}
                  >
                    {a.phoneNumber}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {selectedPhoneNumber._id !== undefined && (
        <CallFlowManagement
          selectedPhoneNumber={selectedPhoneNumber}
          reloadConfig={() => getData()}
          organisation={organisation}
        />
      )}
    </div>
  );
};
export default Organisation_Receiver(CallFlows, (organisation) => {
  return { organisation };
});
