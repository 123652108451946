import React, {useEffect, useMemo, useState} from "react";
import {FaPencilAlt, FaTrash} from "react-icons/fa";
import AlertMessage from "../../../../shared/components/AlertMessage";
import Confirm from "../../../../shared/components/Confirm";
import Field_DropdownInput from "../../../../shared/components/Field_DropdownInput";
import Field_TextInput from "../../../../shared/components/Field_TextInput";
import ContactList from "./contactList";

const TimeoutSelectList = [
  { text: "5 Seconds", value: 5, id: 0 },
  { text: "10 Seconds", value: 10, id: 1 },
  { text: "15 Seconds", value: 15, id: 2 },
  { text: "20 Seconds", value: 20, id: 3 },
  { text: "25 Seconds", value: 25, id: 4 },
  { text: "30 Seconds", value: 30, id: 5 },
];

const ContactListFields = ({ isOrderableList, title, data, setData, errors, isSubmitting, hideRingTimeout = false }) => {
  return (
    <div className="space-y-2">
      {!hideRingTimeout && <Field_DropdownInput
        labelClassName={"text-gray-400"}
        title={"Ring Timeout (per contact)"}
        name={"timeout"}
        value={data.timeout || ""}
        options={TimeoutSelectList}
        onChange={(val) => {
          setData({
            ...data,
            timeout: val,
          });
        }}
        isDisabled={isSubmitting}
        error={errors.timeout}
      />}
      <ContactList
        isOrderableList={isOrderableList}
        title={title}
        list={data.contacts || []}
        onChange={(contacts) =>
          setData({
            ...data,
            contacts,
          })
        }
      />
      {errors.contacts && <p style={{ color: "red" }}>{errors.contacts}</p>}
    </div>
  );
};

const EmailAddressList = ({recipients, onChange, error}) => {
  const [removeRecipientId, setRemoveRecipientId] = useState("");
  const [data, setData] = useState({
    email: "",
    active: true,
  });
  const removeName = useMemo(() => {
    if (removeRecipientId !== "") {
      const a = recipients.filter((a, index) => index === removeRecipientId);
      if(a[0] !== undefined)
        return a[0].email;
    }
    return "";
  }, [removeRecipientId])

  const handleSubmit = () => {
    if (data.email.trim() === "") return;
    if (data.email.length <= 5) return;
    if( data.email.indexOf("@") === -1) return;
    onChange(recipients.concat([data]));
    setData({
      email: "",
      active: true,
    })
  }
  const handleRemove = () => {
    onChange(recipients.filter((item, index) => {
      return index !== removeRecipientId;
    }));
    setRemoveRecipientId("");
  };

  return (
    <>
      {removeRecipientId !== "" && <Confirm
        open={true}
        onCancel={() => setRemoveRecipientId("")}
        onConfirm={() => handleRemove()}
        title={"Remove recipient?"}
        isMiddle
        message={"Are you sure you want to remove recipient: " + removeName}
      />}

      <div className="flex gap-2 justify-around items-center">
        <div className="flex flex-col gap-1 w-full">
          <label className={"text-gray-400"}>Email Address</label>
          <input
            className="w-full p-2 bg-gray-800 rounded-md border border-gray-700"
            type={"email"}
            name={"notify_email"}
            autoComplete={"none"}
            required
            value={data.email}
            onChange={(e) => setData({...data, email: e.target.value})}
          />
          {error && <div className={"text-red-500"}>{error}</div>}
        </div>
        <div className="flex items-center p-2 gap-2 ">
          <button className="bg-green-600 text-white rounded-xl p-2 mt-4" onClick={() => handleSubmit()}>
            Add
          </button>
        </div>
      </div>
      <div className="mt-1 border-t border-gray-700 py-1"></div>
      {recipients.length === 0 ? (
        <AlertMessage.Info>There are no stored recipients to display.</AlertMessage.Info>
      ) : (
        <table className="rounded-md min-w-full divide-y divide-x divide-gray-700 border-y border-gray-700">
          <thead>
          <tr className="rounded-md bg-gray-900">
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {""}
            </th>
          </tr>
          </thead>
          <tbody className="bg-black divide-y divide-gray-700">{recipients.map((recipient, index) => {
            return (
              <tr key={index}>
                <td className="p-2 items-center divide-x-2 divide-gray-700  hover:bg-gray-900">
                  {recipient.email}
                </td>
                <td className="p-2 w-14 items-center divide-x-2 divide-gray-700 hover:bg-gray-900">
                  <div className="flex gap-4 text-xl">
                    <button onClick={() => setRemoveRecipientId(index)} className="text-red-500 text-xl"><FaTrash/></button>
                  </div>
                </td>
              </tr>
            );
          })}</tbody>
        </table>
      )}
    </>
  );
};

const FlowStepStageForm = ({isEdit = false, step, onChange, onClose, onDelete}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepType, setStepType] = useState("");
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (step.data !== undefined) {
      setData(step.data);
    } else {
      setData({});
    }
    if (step.type !== undefined) {
      setStepType(step.type);
    } else {
      setStepType("");
    }
  }, [step]);

  const handleSubmit = () => {
    setErrors({}); // reset the errors

    if (stepType === "") {
      setErrors({
        stepType: "A step type is required",
      });
      return;
    }

    let errors = {};

    if (stepType === "ordered_list" || stepType === "random_list") {
      if (data.timeout === undefined || data.timeout === "") {
        errors.timeout = "You must select a ring timeout";
      }
      if (data.contacts === undefined || data.contacts.length === 0) {
        errors.contacts = "You must select at least one contact";
      }
    }

    if (stepType === "text") {
      if (data.say === undefined || data.say === "") {
        errors.say = "Text can not be empty";
      }
    }

    if (stepType === "voicemail") {
      if (data.name === undefined || data.name === "") {
        errors.name = "Text can not be empty";
      }
      if (data.say === undefined || data.say === "") {
        errors.say = "Text can not be empty";
      }
      if (data.sendTo === undefined || data.sendTo.length === 0) {
        errors.sendTo = "Must have at least one recipient";
      }
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    onChange({
      type: stepType,
      data,
    });
  };

  return (
    <div>
      <h1 className={"text-2xl p-2 text-center"}>{!isEdit ? "Add flow step" : "Modify flow step"}</h1>
      <div className="text-md p-2">
        <div className="flex flex-col gap-2 w-full">
          <Field_DropdownInput
            labelClassName={"text-gray-400"}
            title={"Step Type"}
            name={"stepType"}
            value={stepType}
            options={[
              { text: "Text to speech", value: "text", id: 0 },
              { text: "Ordered list", value: "ordered_list", id: 1 },
              { text: "Random order list", value: "random_list", id: 2 },
              { text: "Voicemail", value: "voicemail", id: 3 },
            ]}
            onChange={(val) => {
              setData({});
              setStepType(val);
            }}
            isDisabled={isSubmitting}
            error={errors.stepType}
          />
        </div>
      </div>
      <div className="p-2">
        {stepType === "text" && (
          <>
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                textArea={true}
                labelClassName={"text-gray-400"}
                title={"Text to be spoken"}
                name={"say"}
                value={data.say || ""}
                onChange={(val) => setData({ ...data, say: val })}
                isDisabled={isSubmitting}
                error={errors.say}
              />
            </div>
          </>
        )}
        {stepType === "ordered_list" && (
          <ContactListFields isOrderableList title={"Call contacts in order"} data={data} setData={(a) => setData(a)} errors={errors} isSubmitting={isSubmitting} />
        )}
        {stepType === "random_list" && (
          <ContactListFields title={"Call contacts in random order"} data={data} setData={(a) => setData(a)} errors={errors} isSubmitting={isSubmitting} />
        )}
        {stepType === "voicemail" && (
          <>
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                labelClassName={"text-gray-400"}
                title={"Voicemail Inbox Name"}
                name={"name"}
                value={data.name || ""}
                onChange={(val) => setData({...data, name: val})}
                isDisabled={isSubmitting}
                error={errors.name}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <Field_TextInput
                textArea={true}
                labelClassName={"text-gray-400"}
                title={"Text to be spoken before beep"}
                name={"say"}
                value={data.say || ""}
                onChange={(val) => setData({...data, say: val})}
                isDisabled={isSubmitting}
                error={errors.say}
              />
            </div>
            <div className={"py-2"}>Send voicemail recording to the following recipients</div>
            <EmailAddressList recipients={data?.sendTo || []} onChange={newList => setData({...data, sendTo: newList})} error={errors.sendTo}/>

            <div className={"py-2"}>Send text & email alert to the following recipients</div>
            <ContactListFields title={"SarStuff Central Users"} data={data} setData={(a) => setData(a)} errors={errors} isSubmitting={isSubmitting} hideRingTimeout />
          </>
        )}
      </div>
      <div className="flex justify-between mt-4 border-t border-gray-700 pt-4">
        {isEdit && <button className="py-2 px-4 bg-gray-500 text-white rounded-lg" onClick={() => onClose()}>
          Cancel
        </button>}
        {isEdit && (
          <button className="py-2 px-4 bg-red-500 text-white rounded-lg" onClick={() => onDelete()}>
            Delete
          </button>
        )}
        <button className="py-2 px-4 bg-green-500 text-white rounded-lg" onClick={handleSubmit}>
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
};
export default FlowStepStageForm;
