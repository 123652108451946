import React from "react";
import {FlowDownArrow} from "./index";
import {GrOrderedList} from "react-icons/gr";
import {FaRandom, FaArrowUp, FaArrowDown, FaPhoneVolume } from "react-icons/fa";

const ContactItem = ({ contact }) => {
  const color = contact._isActive ? "green" : "gray";
  return (
    <div
      className={`text-sm px-2 py-1 border border-${color}-300 rounded bg-${color}-700`}
    >
      {contact.name}
    </div>
  );
};

const FlowStep = ({ step, onClick, onReorder, orderNum, orderTotal }) => {
  const ContactList = ({ contacts }) => {
    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {contacts.map((c, i) => (
          <ContactItem key={i} contact={c} />
        ))}
      </div>
    );
  };

  return (
    <>
      <FlowDownArrow />
      <div className="divide-y divide-gray-700 bg-gray-900 border rounded border-zinc-700">
        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
          <div
            className="flex items-center justify-center lg:col-span-1 col-span-full  cursor-pointer"
            onClick={() => {
              onClick();
            }}
          >
            {step.type === "text" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-16 h-16"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                />
              </svg>
            )}
            {step.type === "ordered_list" && <GrOrderedList className="h-16 w-16"/>}
            {step.type === "random_list" && <FaRandom className="h-16 w-16"/>}
            {step.type === "voicemail" && <FaPhoneVolume  className="h-16 w-16"/>}
          </div>
          <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
            <div className={"grid grid-cols-8"}>
              <div
                className={"col-span-7 flex flex-col cursor-pointer"}
                onClick={() => {
                  onClick();
                }}
              >
                <span className="text-md tracking-wider uppercase dark:text-primary">
                  Type: <strong>{step.type}</strong>
                </span>
                <span className="mt-4 dark:text-gray-300 text-xl md:text-2xl">
                  {step.type === "text" && (
                    <span>
                      Say: <strong>{step.data?.say}</strong>
                    </span>
                  )}
                  {step.type === "ordered_list" && (
                    <div>
                      <div>
                        Total Contacts:{" "}
                        <strong>{step.data.contacts.length}</strong>
                        <br />
                        Timeout: <strong>{step.data.timeout}</strong>
                      </div>
                      <ContactList contacts={step.data.contacts} />
                    </div>
                  )}
                  {step.type === "random_list" && (
                    <div>
                      <div>
                        Total Contacts:{" "}
                        <strong>{step.data.contacts.length}</strong>
                        <br />
                        Timeout: <strong>{step.data.timeout}</strong>
                      </div>
                      <ContactList contacts={step.data.contacts} />
                    </div>
                  )}
                  {step.type === "voicemail" && (
                    <>
                      <div>
                        Voicemail Inbox Name: <strong>{step.data?.name}</strong>
                      </div>
                      <div>
                        Say: <strong>{step.data?.say}</strong>
                      </div>
                      <div>
                        Send To:
                        <ul className={"list-disc"}>
                          {step.data.sendTo.map((r, i) => (
                            <li key={i} className={"list-item ml-10"}>{r.email}</li>
                          ))}
                        </ul>
                      </div>
                      <ContactList contacts={step.data?.contacts || []} />
                    </>
                  )}
                </span>
              </div>
              <div className={"col-span-1 text-right"}>
                {orderNum !== 0 && (
                  <button className={"my-2"} onClick={() => onReorder(-1)}>
                    <FaArrowUp className="h-10 w-10" />
                  </button>
                )}
                {orderNum + 1 !== orderTotal && (
                  <button className={"my-2"} onClick={() => onReorder(1)}>
                    <FaArrowDown className="h-10 w-10" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FlowStep;
